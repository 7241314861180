import React from "react"
import { Link } from "gatsby"
import { gsap } from "gsap"
import Video from "./Video"
import useTabIsUsed from "../../hooks/useTabIsUsed"
import getSvgLogoComponent from "../../helpers/getSvgLogoComponent"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import addLineBreaks from "../../helpers/addLineBreaks"
import getClassNamesByTabIsUsedState from "../../helpers/getClassNamesByTabIsUsedState"

const Description = ({ headerText, body }) => {
  const richTextOptions = {
    renderText: addLineBreaks,
  }
  return (
    <div className="text-center bg-black bg-opacity-90 p-4 m-4 rounded-xl font-light ">
      <h2 className="lg:text-lg 2xl:text-xl text-primary">{headerText}</h2>
      {renderRichText(body, richTextOptions)}
    </div>
  )
}

const OneVideoLayout = ({ products, video, poster }) => {
  const tabIsUsed = useTabIsUsed()
  const videoRef = React.useRef()

  function playVideo() {
    function playAnimation() {
      gsap.timeline().to(videoRef.current, {
        autoAlpha: 1,
      })
    }
    playAnimation()
    videoRef.current.play()
  }
  function pauseVideo() {
    function pauseAnimation() {
      gsap.timeline().to(videoRef.current, {
        autoAlpha: 0.3,
      })
    }
    pauseAnimation()
    videoRef.current.pause()
  }

  return (
    <>
      <Video
        videoRef={videoRef}
        className="fixed inset-0 h-full min-w-full object-cover"
        title={video.title}
        url={video.json.secure_url}
        poster={poster}
        loop
        autoPlay
        muted
      />
      <div className="flex flex-col lg:flex-row justify-center items-center relative w-80vw min-h-screen m-auto mt-24">
        {products.map(({ id, description, link: { slug, text, logo } }) => {
          const SvgComponent = getSvgLogoComponent(logo)
          return (
            <div key={id} className="flex-1 relative">
              <div className="w-64 mx-auto">
                <Link
                  to={slug}
                  onPointerEnter={pauseVideo}
                  onPointerLeave={playVideo}
                  className={`${getClassNamesByTabIsUsedState(
                    tabIsUsed
                  )} block`}
                >
                  <SvgComponent title={text} />
                </Link>
              </div>
              <Description headerText={text} body={description} />
            </div>
          )
        })}
      </div>
    </>
  )
}
export default OneVideoLayout
