import React from "react"
import Layout from "../components/reusables/Layout"
import SEO from "../components/reusables/seo"
import OneVideoLayout from "../components/reusables/OneVideoLayout"
import { graphql } from "gatsby"

export default function ProductPageTemplate({ data }) {
  const products = data.page.products
  return (
    <>
      <SEO title={data.page.title} />
      <Layout>
        <section id="main">
          <OneVideoLayout
            products={products}
            video={data.page.video}
            poster={data.page.video.poster.localFile.publicURL}
            data={data}
          />
        </section>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    page: contentfulProductPage(slug: { eq: $slug }) {
      title
      products {
        id
        link {
          slug
          text
          logo {
            title
          }
        }
        description {
          raw
        }
      }
      video {
        title
        poster {
          localFile {
            publicURL
          }
        }
        json: childContentfulCloudinaryVideoVideoJsonNode {
          secure_url
        }
      }
    }
  }
`
